import { UmbracoTypes } from '~/lib/data-contract';
import { useSKUResults } from '~/services';
import { memo } from 'react';
import { CarouselWithItems } from './helpers';

function M30SpecificProductNumbersCarousel({
    headline,
    productNumbers,
    link,
}: Omit<UmbracoTypes.IM30SpecificProductNumbersCarouselModule, 'type'>) {
    const { data } = useSKUResults({ productNumbers, isMobile: false });

    return <CarouselWithItems data={data?.results?.items} headline={headline} link={link} />;
}

export default memo(M30SpecificProductNumbersCarousel);
