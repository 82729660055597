import { ProductCard } from '~/features/ProductList/components/ProductCard';
import { Loop54Product, useTranslations, useUserProfile } from '~/services';
import { RefObject, memo, useEffect, useMemo } from 'react';
import { ModuleCarousel } from '~/templates/blocks/shared/ModuleCarousel';
import { ILink } from '~/lib/data-contract/umbraco-api-model';
import { useSession } from 'next-auth/react';

type Props = {
    data: Loop54Product[] | undefined;
    headline: string | undefined;
    setHasProducts?: (status: boolean) => void;
    inputRef?: RefObject<HTMLDivElement>;
    link?: ILink;
};

function CarouselWithItems({ data, headline, inputRef, link, setHasProducts }: Props) {
    const { translate } = useTranslations();
    const { data: sessionData } = useSession();
    const { data: profile } = useUserProfile();
    const token = sessionData?.accessToken ?? null;
    const customerId = profile?.customer?.id ?? '';

    const productsWithPrices =
        useMemo(
            () =>
                data?.map((item, index) => {
                    return {
                        key: item.SKU + index,
                        product: item,
                    };
                }),
            [data],
        ) || [];
    const productItems =
        productsWithPrices?.map((item) => (
            <ProductCard
                key={item.key}
                product={item.product}
                translate={translate}
                itemListName={headline}
                customerId={customerId}
                token={token}
            />
        )) || [];

    useEffect(() => {
        if (setHasProducts) setHasProducts(productItems.length > 0);
    }, [productItems.length, setHasProducts]);

    if (productItems?.length < 1) {
        return null;
    }

    return (
        <ModuleCarousel
            items={productItems}
            headline={headline}
            isProducts
            inputRef={inputRef}
            link={link}
        />
    );
}

export default memo(CarouselWithItems);
